/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer"
import "../assets/scss/styles.scss"

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  require('smooth-scroll')('a[href*="#"]');
}

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div className="page" >
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
