import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header id="header">
    <Link to="/" className="logo">
      <img src={require("../assets/images/logo.svg")} />
    </Link>
    <nav className="nav">
      <Link to="/#services" className="nav-link">Services</Link>
      <Link to="/#technologies" className="nav-link">Technologies</Link>
      <Link to="/#portfolio" className="nav-link">Portfolio</Link>
      <Link to="/#contacts" className="nav-link">Contacts</Link>
      <div className="social-icons">
        <a href="https://twitter.com/di_sky_tech">
          <img src={require("../assets/images/twitter-icon.svg")} className="social-icon"/>
        </a>
        <a href="https://www.linkedin.com/company/di-sky-tech" >
          <img src={require("../assets/images/in-icon.svg")} className="social-icon"/>
        </a>
      </div>
    </nav>
  </header>
)

export default Header
