import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer id="contacts">
    <div className="footer-info-wrapper">
      <nav className="footer-nav-wrapper">
        <div className="footer-nav">
          <Link to="/#services" className="nav-link">Services</Link>
          <Link to="/#technologies" className="nav-link">Technologies</Link>
          <Link to="/#portfolio" className="nav-link">Portfolio</Link>
          <Link to="/#contacts" className="nav-link">Contacts</Link>
        </div>

        <div className="social-icons">
          <a href="https://twitter.com/di_sky_tech" >
            <img src={require("../assets/images/twitter-icon-circle.svg")} className="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/di-sky-tech" >
            <img src={require("../assets/images/in-icon-circle.svg")} className="social-icon" />
          </a>
        </div>
      </nav>
      <div className="footer-address-wrapper">
        <span>11 Gorodok Stroitelei, apt. 127</span>
        <span>Bishkek, Kyrgyzstan</span>
        <span>720001</span>
      </div>
      <div className="footer-email-wrapper">
        <h3>Get in touch</h3>
        <span>office@di-sky.tech</span>
        <span>+996 771 42 52 93</span>
      </div>
    </div>
    <div className="terms">
      <span >© 2019 Di Sky Technology, LLC</span>
      <span ><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms of Use</Link></span>
    </div>

  </footer>
)

export default Footer
